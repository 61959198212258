export enum ServiceBaseUrls {
	Order = "/orders",
	Marathons = "/Marathons",
	Events = "/Events",
	Booking = "/booking",
	UserAccount = "/UserAccount",
	Credits = "/UserCredits",
	Team = "/team",
	Ticket = "/tickets",
	Participants = "/participants",
	ITabs = "/itab-events",
	StockManagement = "/stock-management",
	StockOrderManagement = "/stock-order-management",
}

export enum ApiServices {
	OrderService,
	EventService,
	AccommodationService,
	AnalyticsService,
	AuthenticationService,
	CreditService,
}

export enum SWRRequestKeys {
	CustomQuestions = "customQuestions",
	EventProducts = "eventProducts",
	Accommodation = "accommodations",
	RemainingUserCredits = "remainingUserCredits",
	EventProxy = "eventProxy",
	Feed = "feed",
	Tld = "tld",
}
